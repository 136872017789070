const Footer = () => {
    return (
        <section class="footer">
            <div class="wrapper">
                <div class="container">
                    <p>&copy; 2021 Luciano Kholos. All Rights Reserved.</p>
                </div>
            </div>
        </section>
    )
}

export default Footer
